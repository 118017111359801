import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
// import { UserProvider } from "./UserContext";
import { Provider } from 'react-redux';
import store from './store/store';

import { useTranslation } from 'react-i18next';
import { animated, useSpring } from '@react-spring/web';
import { BiChat } from 'react-icons/bi';
import { FaAngleDoubleDown } from 'react-icons/fa';

import PrivateRoute from './components/secureRoutes/PrivateRoute';
import { UserProvider } from './components/context/AuthContext';
import { CartProvider } from './components/context/CartContext';
import AboutUs from './components/AboutUs';
import Chatbot from './components/AI/Chatbot';

import Home from './pages/HomePage';
import StudentListPage from './pages/StudentList';
import CourseLearningPage from './pages/CourseLearning';
import AddressesPage from './pages/AddressesPage';
import PrivacyPolicyPage from './pages/privacyPolicy';
import TermsConditionPage from './pages/termsCondition';
import FaqsPage from './pages/FaqsPage';
import ProfilePage from './pages/ProfilePage';
import NotificationsPage from './pages/NotificationsPage';
import MyOrders from './pages/MyOrders';
import Askdoubt from './pages/Chat';
import InstructorCoursesPage from './pages/InstructorCoursesPage';
// import InstructorCoursePage from './pages/InstructorCoursePage';
import DynamicRoutePage from './pages/DynamicRoutePage';
import StudentMyLearningPage from './pages/StudentMyLearning';
import CheckoutPage from './pages/Checkout';
import CourseDetailsPage from './pages/CourseDetailsPage';
import AllCourses from './pages/AllCoursesPage';

//css
import './assets/styles/footer.css';
import './assets/styles/comman.css';
import './assets/styles/header.css';
import './assets/styles/home.css';
import './assets/styles/modals.css';
import './assets/styles/courses.css';
import './assets/styles/profile.css';
import './assets/styles/address.css';
import './assets/styles/instructor.css';
import './assets/styles/lang.css';
import '../src/assets/styles/chatbot.css';

import CoursePage from './components/CourseLearning/CoursePage';
import InstructorCoursePage from './components/instructorCourse/CoursePage';
// demo course page

function App() {
    const { i18n } = useTranslation();
    const [openChatbot, setOpenChatbot] = useState(false);
    const [chatbotAvailable, setChatbotAvailable] = useState(true);

    const springs = useSpring({
        transform: openChatbot ? 'rotate(360deg)' : 'rotate(0deg)',
    });
    const [props, set] = useSpring(() => ({ scale: 1 }));

    useEffect(() => {
        let body = document.querySelector('body');
        if (i18n.language !== undefined) {
            if (body.classList.contains('i18n-ltr') && i18n.language == 'ar') {
                body.className = body.className.replace('i18n-ltr', 'i18n-rtl');
            } else if (body.classList.contains('i18n-rtl') && i18n.language == 'en') {
                body.className = body.className.replace('i18n-rtl', 'i18n-ltr');
            }
        }
    }, [i18n, i18n.language]);

    return (
        <Provider store={store}>
            <UserProvider>
                <CartProvider>
                    <ModalProvider>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    {Home}
                                </Route>
                                <Route exact path="/home">
                                    {Home}
                                </Route>
                                <Route exact path="/about-us">
                                    {AboutUs}
                                </Route>
                                <Route exact path="/notifications">
                                    {NotificationsPage}
                                </Route>
                                <Route exact path="/courses/:type">
                                    {AllCourses}
                                </Route>
                                <Route exact path="/courses/:type/:sub_type">
                                    {AllCourses}
                                </Route>
                                <Route exact path="/checkout">
                                    {CheckoutPage}
                                </Route>
                                <Route exact path="/course-details/:id">
                                    {CourseDetailsPage}
                                </Route>
                                <Route exact path="/course-name/:id">
                                    {DynamicRoutePage}
                                </Route>
                                <Route exact path="/chat/:id">
                                    {Askdoubt}
                                </Route>
                                <Route exact path="/my-courses">
                                    {InstructorCoursesPage}
                                </Route>
                                <Route exact path="/my-students">
                                    {StudentListPage}
                                </Route>
                                <Route exact path="/privacy-policy">
                                    {PrivacyPolicyPage}
                                </Route>
                                <Route exact path="/terms-condition">
                                    {TermsConditionPage}
                                </Route>
                                <Route exact path="/faqs">
                                    {FaqsPage}
                                </Route>

                                <PrivateRoute exact path="/course">
                                    <CoursePage setChatbotAvailable={setChatbotAvailable} />
                                </PrivateRoute>

                                {/* <PrivateRoute exact path="/course" component={() => <CourseLearningPage setChatbotAvailable={setChatbotAvailable} />} /> */}
                                {/* <PrivateRoute exact path="/course" component={() => <CoursePage setChatbotAvailable={setChatbotAvailable} />} /> */}
                                <PrivateRoute exact path="/profile" component={ProfilePage} />
                                <PrivateRoute exact path="/my-orders" component={MyOrders} />
                                <PrivateRoute exact path="/instructor/course">
                                    <InstructorCoursePage setChatbotAvailable={setChatbotAvailable} />
                                </PrivateRoute>
                                <PrivateRoute exact path="/student-learning" component={StudentMyLearningPage} />
                                <PrivateRoute exact path="/addresses" component={AddressesPage} />
                                <PrivateRoute exact path="/addresses/:id" component={AddressesPage} />
                            </Switch>
                        </Router>

                        {chatbotAvailable ? (
                            <animated.div
                                id="chatbot-icon"
                                style={{ transform: props.scale.to((s) => `scale(${s})`) }}
                                onClick={() => {
                                    setOpenChatbot(!openChatbot);
                                    set({ scale: 0.7 });
                                    setTimeout(() => {
                                        set({ scale: 1 });
                                    }, 150);
                                }}
                            >
                                {openChatbot ? (
                                    <animated.div style={{ ...springs }}>
                                        <FaAngleDoubleDown />
                                    </animated.div>
                                ) : (
                                    <animated.div style={{ ...springs }}>
                                        <BiChat />
                                    </animated.div>
                                )}
                            </animated.div>
                        ) : null}

                        {openChatbot ? (
                            // <Chatbot user_email={loggedIn ? user.email : null} user_first_name={loggedIn ? user.first_name : null} />
                            <Chatbot user_email={null} user_first_name={null} />
                        ) : null}
                    </ModalProvider>
                </CartProvider>
            </UserProvider>
        </Provider>
    );
}

export default App;
