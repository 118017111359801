import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../context/AuthContext';
import Select from 'react-select';
import { countryAction } from '../../containers/Utils/actions/country';
import { addAddressApi } from '../services/address';
import { showError, showSuccess } from '../Utils/helper';
import {
    kuwaitGovernatesEn,
    kuwaitGovernatesAr,
    bahrainGovernoratesAr,
    bahrainGovernoratesEn,
    saudiArabiaGovernoratesAr,
    saudiArabiaGovernoratesEn,
} from '../Utils/helper';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const customStyles = {
    padding: '5px 0px',
    borderRadius: '11px',
    borderCcolor: 'red',
};

export default function AddAddress({ onAddAddress }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { loggedIn, loadingUser, user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const country = useSelector((state) => state.country);
    const [countryName, setCountryName] = useState({
        value: 112,
        label: t('countries.Kuwait', 'Kuwait'),
    });
    const isArabic = i18n.language === 'ar';
    const [cityName, setCityName] = useState(null);
    const [governorateName, setGovernorateName] = useState(null);
    const [availableAreas, setAvailableAreas] = useState([]);
    const [details, setDetails] = useState({
        category: '',
        pin_code: '',
        country_id: '',
        state: '',
        block: '',
        street: '',
        apartment: '',
        directions: '',
        governorate: '',
        city: '',
        address: '',
    });

    let city = [];
    let governorate = [];

    const handleAddAddress = (newAddress) => {
        (
            onAddAddress ||
            (async () => {
                try {
                    const resp = await addAddressApi(newAddress);
                    if (resp.success) {
                        showSuccess(t('alertMessage.addressAdded'));
                    } else {
                        showError(t('alertMessage.addressAddFailed'));
                    }
                } catch (error) {
                    console.error('Error adding address:', error);
                    showError(t('alertMessage.addressAddFailed'));
                }
            })
        )(newAddress);
    };

    countryName.value === 112
        ? isArabic
            ? Object.keys(kuwaitGovernatesAr).map((_city) => {
                  let data = { label: _city, value: _city };
                  governorate.push(data);
              })
            : Object.keys(kuwaitGovernatesEn).map((_city) => {
                  let data = { label: _city, value: _city };
                  governorate.push(data);
              })
        : countryName.value === 17
          ? isArabic
              ? Object.keys(bahrainGovernoratesAr).map((_city) => {
                    let data = { label: _city, value: _city };
                    governorate.push(data);
                })
              : Object.keys(bahrainGovernoratesEn).map((_city) => {
                    let data = { label: _city, value: _city };
                    governorate.push(data);
                })
          : isArabic
            ? Object.keys(saudiArabiaGovernoratesAr).map((_city) => {
                  let data = { label: _city, value: _city };
                  governorate.push(data);
              })
            : Object.keys(saudiArabiaGovernoratesEn).map((_city) => {
                  let data = { label: _city, value: _city };
                  governorate.push(data);
              });

    useEffect(() => {
        setLoading(true);
        dispatch(countryAction.getCountry());
    }, []);

    async function submitAddress(e) {
        setSpinner(true);
        if (!loggedIn) {
            showError(t('alertMessage.signUpToPurchase'));
            return;
        }

        let payloads = {
            ...details,
            country_id: countryName.value,
            city: cityName?.value,
            governorate: governorateName?.value,
            state: governorateName?.value,
        };

        const resp = await handleAddAddress(payloads);
        if (resp) {
            showSuccess(t('alertMessage.success'));
        } else {
            console.log('Error: ', resp);
        }
        setSpinner(false);
    }

    function changeHandler(e) {
        const { name, value } = e.target;

        setDetails({
            ...details,
            [name]: value,
        });
    }

    function selectGovernorate(governorate) {
        setGovernorateName(governorate);

        countryName.value === 112
            ? isArabic
                ? setAvailableAreas(kuwaitGovernatesAr[governorate.value] || [])
                : setAvailableAreas(kuwaitGovernatesEn[governorate.value] || [])
            : countryName.value === 17
              ? isArabic
                  ? setAvailableAreas(bahrainGovernoratesAr[governorate.value] || [])
                  : setAvailableAreas(bahrainGovernoratesEn[governorate.value] || [])
              : isArabic
                ? setAvailableAreas(saudiArabiaGovernoratesAr[governorate.value] || [])
                : setAvailableAreas(saudiArabiaGovernoratesEn[governorate.value] || []);
    }

    return (
        <section className="pt_50 pb_50" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
            <div className="add-address-container container">
                <h2 className="mb_30">{t('common.enterAddressDetails')}</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="required-field">{t('common.category')}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="category"
                                value={details.category}
                                placeholder={t('common.homeCompanyPent')}
                                onChange={changeHandler}
                                required
                            />
                        </div>
                        <>
                            <div className="form-group">
                                <label className="required-field">{t('common.block')}</label>
                                <input
                                    type="text"
                                    name="block"
                                    className="form-control"
                                    placeholder={t('common.block')}
                                    required
                                    value={details.block}
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className="form-group">
                                <label className="required-field">{t('common.street')}</label>
                                <input
                                    type="text"
                                    name="street"
                                    value={details.street}
                                    onChange={changeHandler}
                                    className="form-control"
                                    placeholder={t('common.street')}
                                    required
                                />
                            </div>
                        </>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="required-field">{t('common.houseBuilding')}</label>
                            <input
                                type="text"
                                name="house"
                                value={details.house}
                                onChange={changeHandler}
                                className="form-control r"
                                placeholder={t('common.houseBuilding')}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('common.apartment')}</label>
                            <input
                                type="text"
                                name="apartment"
                                value={details.apartment}
                                onChange={changeHandler}
                                className="form-control"
                                placeholder={t('common.apartment')}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <>
                            <div className="form-group">
                                <label className="required-field">{t('common.country')}</label>
                                <Select
                                    name="form-field-name"
                                    className="react_select"
                                    value={countryName}
                                    onChange={(selected) => {
                                        setCountryName(selected);
                                    }}
                                    options={country.country.map((item) => {
                                        return {
                                            label: t('countries.' + item.label, item.label),
                                            value: item.value,
                                        };
                                    })}
                                    styles={customStyles}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="required-field">{t('common.governorate')}</label>
                                <Select
                                    placeholder={t('common.select')}
                                    options={governorate}
                                    className="react_select"
                                    value={governorateName}
                                    onChange={(selected) => {
                                        selectGovernorate(selected);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label className="required-field">{t('common.chooseArea')}</label>
                                <Select
                                    placeholder={t('common.select')}
                                    options={availableAreas.map((item) => {
                                        return { label: item, value: item };
                                    })}
                                    className="react_select"
                                    value={cityName}
                                    onChange={(selected) => {
                                        setCityName(selected);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label for="">{t('common.jaddahDirections')}</label>
                                <textarea
                                    name="directions"
                                    value={details.directions}
                                    onChange={changeHandler}
                                    rows="5"
                                    className="form-control"
                                ></textarea>
                            </div>
                        </>
                        <button className="btn_primary big_btn full_btn mt-3" type="submit" onClick={submitAddress}>
                            {spinner && <span className="mr-2 spinner-border spinner-border-sm" />}
                            {t('common.save')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
