// src/components/CourseLearning/Course/Unavailable.jsx
import React from 'react';
import './Unavailable.css';
import ErrorIcon from '@mui/icons-material/Error';

const Unavailable = () => {
    return (
        <div className="unavailable">
            <ErrorIcon style={{ fontSize: '48px', color: 'var(--primary)' }} />
            <h2>Content Unavailable</h2>
            <p>The content you're trying to view is currently unavailable.</p>
        </div>
    );
};

export default Unavailable;
