import React, { useState, useContext, useEffect, useRef, useCallback, Suspense } from 'react';
import { Redirect, withRouter, useLocation } from 'react-router-dom';
import { animated, useSpring, useSprings } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/AuthContext';

import Loading from '../Common/Loading';
import ErrorBoundary from '../Common/ErrorBoundary';

import PageLayout from '../Layouts/PageLayout';
import Sidebar from './Sidebar/Sidebar';
import Flashcard from './Course/Flashcard';
// import Loader from './Course/Loader';

// import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';

import Test from './Course/Test';
// import Test from './Quiz';
import PDFReader from './Course/PDFReader';
// import LiveSession from './LiveSession';
import LiveSession from './Course/LiveSession';
import Quiz from './Course/Quiz';

import Unavailable from '../Common/Unavailable';

// Import audio and video player components
import AudioPlayer from 'react-h5-audio-player';

import { ToastContainer } from 'react-toastify';

import {
    Player,
    ControlBar,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    BigPlayButton,
    PlaybackRateMenuButton,
} from 'video-react';

// Import services for API calls
import { getCourseById, lessonCompletionUpdate } from '../services/student';
import { postComments } from '../services/discussionForum';
import getTranslationFromMany from '../../services/helper';
import { showError, showSuccess } from '../Utils/helper';

import 'react-h5-audio-player/lib/styles.css';
import 'video-react/dist/video-react.css';
import './CoursePage.css';

import TutorRevamp from '../AI/TutorRevamp';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { FaRobot } from 'react-icons/fa';

const CoursePage = (props) => {
    const [activeComponent, setActiveComponent] = useState();
    const { loggedIn } = useContext(UserContext);
    const { user } = useContext(UserContext);

    // *course page*
    const [data, setData] = useState([]);
    const playerRef = useRef(null);
    const course_id = props.location.state;
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [uniqueId, setUniqueId] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [videoState, setVideoState] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    // const [viewMode, setViewMode] = useState('list');
    const [videoEnd, setVideoEnd] = useState(false);
    const [instructorToAsk, setInstructorToAsk] = useState(null);
    const [chapter_id, setChapterId] = useState(null);

    // *sidebar*
    const [view, setView] = useState('');

    // *flashcard*
    const [chapterData, setChapterData] = useState();
    const [flashcards, setFlashcards] = useState([]);
    // const [viewMode, setViewMode] = useState('list');

    // *quiz*
    const [quiz, setQuiz] = useState([]);
    const [check, setCheck] = useState([]);
    const [createNewQuiz, setCreateNewQuiz] = useState(false);

    // *loading *
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    // *others
    const heightRef = useRef(null);
    const { t, i18n } = useTranslation();
    // const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(false);
    const [height, setHeight] = useState(0);

    const contentRef = useRef(null); // Reference for content
    const sidebarRef = useRef(null); // Reference for sidebar

    const [resp, setResp] = useState([]);

    const [openTutor, setOpenTutor] = useState(false); // Tutor visibility state
    const { setChatbotAvailable } = props; // Function to set chatbot availability

    // Animation for the tutor icon
    const springs = useSpring({
        transform: openTutor ? 'rotate(360deg)' : 'rotate(0deg)',
    });

    // Spring properties for scaling
    const [springProps, set] = useSpring(() => ({ scale: 1 }));

    console.log('--------course page Debugging:--------');
    // console.log('course page - quiz', quiz);
    // console.log('course page - data', data);
    // console.log('course page - view', view);
    // console.log('course page - view length', data?.chapter[0]?.lessons[0]?.quiz?.length);
    // console.log('course page - view video type', data?.chapter[0]?.lessons[0]?.media[0]?.media_type);
    // console.log('course page - media type', data?.chapter[0]?.lessons[0]?.media[0]);
    // console.log('course page - flashcards', flashcards);
    // console.log('course page - currentLesson', currentLesson);
    // console.log('course page - loading', loading);
    // console.log('course page - contentRef', contentRef);
    // console.log('course page - sidebarRef', sidebarRef);
    // console.log('course page - user', user);
    // console.log('course page - resp', resp);
    // console.log('course page - openTutor', openTutor);
    // console.log('props', props);
    console.log('course page - videoUrl', videoUrl);
    console.log('view', view);

    async function toGetCourseById() {
        setLoading(true);
        const resp = await getCourseById(course_id);
        // console.log('course page - resp', resp);
        // console.log('course page - resp data', resp?.data);
        setResp(resp);
        if (resp.success) {
            setData(resp.data);
            if (resp?.data?.chapter[0]?.lessons[0]?.media[0]?.is_downloadable === 1) {
                setIsDownloadable(true);
            }
            setVideoUrl(resp?.data?.chapter[0]?.lessons[0]?.media[0]?.url);
            setCurrentLesson(resp?.data?.chapter[0]?.lessons[0]);
            setView(
                resp?.data?.chapter[0]?.lessons[0]?.quiz?.length > 0
                    ? 'quiz'
                    : resp?.data?.chapter[0]?.lessons[0]?.media[0]?.media_type
            );
            setLoading(false);
        } else {
            showError('Somthing went wrong');
            setLoading(false);
            console.log('course page - error', resp);
        }
    }

    // Function to update lesson completion status
    async function lessonCompletionUpdateApi() {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: currentLesson?.chapter_id,
            lesson_id: currentLesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCounter(false);
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t('alertMessage.lessonCompletedSuccess'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to mark a lesson as completed
    async function markAsCompleted(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t('alertMessage.lessonCompleted'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to unmark a lesson as completed
    async function unmarkComplete(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck(check.filter((item) => item !== resp?.data?.lesson_id));
            showSuccess(t('alertMessage.success'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    const renderActiveComponent = () => {
        // Check for unavailable content
        // if (!currentLesson || !view || (view !== 'quiz' && view !== 'live-session' && !videoUrl)) {
        //     return <Unavailable />;
        // }
        // if (!currentLesson || !view || (view !== 'quiz' && view !== 'live-session' && !videoUrl)) {
        //     return <Unavailable />;
        // }
        // if (!view) {
        //     return <Unavailable />;
        // }
        const components = {
            Course: (
                <div className="course__container">
                    {view === 'video' && (
                        <div className="course_video">
                            <Player
                                src={process.env.REACT_APP_CONTENT_URL + videoUrl}
                                ref={playerRef}
                                fluid={true}
                                width="100%"
                                height="100%"
                            >
                                <BigPlayButton position="center" />

                                <ControlBar autoHide={false}>
                                    <PlayToggle />
                                    <ReplayControl seconds={10} order={2.2} />
                                    <ForwardControl seconds={10} order={3.2} />
                                    <PlaybackRateMenuButton rates={[2, 1, 1.75, 1.5]} />
                                </ControlBar>
                            </Player>
                        </div>
                    )}

                    {view === 'image' && (
                        <img src={process.env.REACT_APP_ASSETS + videoUrl} class="responsive-image" alt={data?.name} />
                    )}
                    {view === 'audio' && (
                        <div className="course_video">
                            <AudioPlayer
                                autoPlay
                                src={process.env.REACT_APP_ASSETS + videoUrl}
                                onEnded={() => lessonCompletionUpdateApi()}
                                showSkipControls={false}
                                loop={false}
                            />
                        </div>
                    )}

                    {view === 'pdf' && <PDFReader videoUrl={videoUrl} isDownloadable={isDownloadable} />}
                    {view === 'live-session' && (
                        <LiveSession
                            name={currentLesson?.name}
                            url={currentLesson?.live_session[0]?.URL}
                            date={currentLesson?.live_session[0]?.session_date}
                        />
                    )}
                    {view === 'quiz' && (
                        <Test
                            key={uniqueId}
                            quiz={currentLesson}
                            course_id={course_id}
                            markAsCompleted={markAsCompleted}
                            uniqueId={uniqueId}
                        />
                    )}
                    {!view && <Unavailable />}
                </div>
            ),
            Quiz: (
                <Quiz
                    // activeSidebar={activeSidebar}
                    chapterData={chapterData}
                    // handleActiveSidebar={handleActiveSidebar}
                    chapter={chapterData?.chapterName}
                    course_id={data?.id}
                    chapter_id={chapter_id}
                    quiz={quiz}
                    setCreateNewQuiz={setCreateNewQuiz}
                    // createNewQuiz={createNewQuiz}
                />
            ),
            Flashcard: (
                <Flashcard
                    chapterData={chapterData}
                    chapter={chapterData?.chapterName}
                    course_id={data?.id}
                    chapter_id={chapter_id}
                    flashcards={flashcards}
                />
            ),
        };
        return components[activeComponent] || components.Course;
    };

    // Function to handle instructor selection for questions
    const handleChooseQAInstructor = (event) => {
        setInstructorToAsk(event.target.value);
    };

    // Effect to prevent right-click context menu and fetch course data
    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        toGetCourseById();
    }, []);

    // Effect to subscribe to video player state changes
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.subscribeToStateChange((e) => {
                setVideoState(e);
            });
        }
    });

    // Effect to handle video completion
    useEffect(() => {
        if (videoState && videoState?.ended && !videoEnd) {
            lessonCompletionUpdateApi();
            setVideoEnd(true);
        }
    }, [videoState]);

    // Effect to redirect if not logged in
    useEffect(() => {
        if (!loggedIn) {
            <Redirect to="/" />;
        }
    }, [loggedIn]);

    useEffect(() => {
        setChatbotAvailable(false);
        return () => {
            setChatbotAvailable(true);
        };
    }, []);

    useEffect(() => {
        let resizeObserver;

        // Debounce the resize callback
        const updateHeight = () => {
            if (contentRef.current && sidebarRef.current) {
                requestAnimationFrame(() => {
                    sidebarRef.current.style.height = `${contentRef.current.clientHeight}px`;
                });
            }
        };

        if (contentRef.current) {
            resizeObserver = new ResizeObserver((entries) => {
                // Use requestAnimationFrame to avoid the loop warning
                window.requestAnimationFrame(() => {
                    updateHeight();
                });
            });

            resizeObserver.observe(contentRef.current);
        }
        // Cleanup
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [data]);

    return (
        <>
            <PageLayout>
                <div className="course-page">
                    <div className="course-page__sidebar" ref={sidebarRef}>
                        <Sidebar
                            setActiveComponent={setActiveComponent}
                            courseData={data}
                            setChapterData={setChapterData}
                            currentLesson={currentLesson}
                            check={check}
                            markAsCompleted={markAsCompleted}
                            unmarkComplete={unmarkComplete}
                            setCurrentLesson={setCurrentLesson}
                            setView={setView}
                            setVideoUrl={setVideoUrl}
                            setVideoEnd={setVideoEnd}
                            setIsDownloadable={setIsDownloadable}
                            setUniqueId={setUniqueId}
                            course_id={course_id}
                            course_title={data?.name}
                            setFlashcards={setFlashcards}
                            // setViewMode={setViewMode}
                            // viewMode={viewMode}
                            setQuiz={setQuiz}
                            setChapterId={setChapterId}
                            setLoading={setLoading}
                            // setError={setError}
                            loading={loading}
                            // error={error}
                            setCreateNewQuiz={setCreateNewQuiz}
                            createNewQuiz={createNewQuiz}
                        />
                    </div>

                    <ErrorBoundary>
                        <div className="course-page__content" ref={contentRef}>
                            {/* <Suspense fallback={<Loading type="text" />}>{renderActiveComponent()}</Suspense> */}
                            {loading ? <Loading type="text" /> : renderActiveComponent()}
                            {/* {renderActiveComponent()} */}
                            <ToastContainer />
                        </div>
                    </ErrorBoundary>
                </div>
            </PageLayout>
            <>
                <animated.div
                    id="chatbot-icon"
                    style={{ transform: springProps.scale.to((s) => `scale(${s})`) }}
                    onClick={() => {
                        setOpenTutor(!openTutor);
                        set({ scale: 0.7 });
                        setTimeout(() => {
                            set({ scale: 1 });
                        }, 150);
                    }}
                >
                    {openTutor ? (
                        <animated.div style={{ ...springs }}>
                            <FaAngleDoubleDown />
                        </animated.div>
                    ) : (
                        <animated.div style={{ ...springs }}>
                            <FaRobot style={{ marginBottom: '5px' }} />
                        </animated.div>
                    )}
                </animated.div>

                {openTutor ? <TutorRevamp course_name={data?.name} /> : null}
            </>
        </>
    );
};

export default withRouter(CoursePage);
