import { useState, useEffect, useContext } from 'react';
import { OpenAI } from 'openai';
import { getStudentThreads, putStudentThreads, getStudentCourse } from '../../services/student';
import { useTranslation } from 'react-i18next';

import {
    upvoteQuizQuestion,
    unupvoteQuizQuestion,
    downvoteQuizQuestion,
    undownvoteQuizQuestion,
    favoriteQuizQuestion,
    getQuizQuestions,
} from '../../services/ai';

import { UserContext } from '../../context/AuthContext';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

const assistant = await openai.beta.assistants.retrieve(process.env.REACT_APP_QUIZZES_ASSISTANT_ID);

function QuizGenerate(props) {
    // User and Thread Management
    const { chapter, course_id, setQuiz, setActiveComponent, loading, setLoading, setCreateNewQuiz, createNewQuiz } =
        props;
    const { id: chapter_id, name: chapter_name } = chapter;

    const [threadID, setThreadID] = useState('');
    const [quizScore, setQuizScore] = useState([]);
    // const [quiz, setQuiz] = useState([]);
    const [quizSubmited, setQuizSubmitted] = useState(false);

    const { user } = useContext(UserContext);

    async function createMessage() {
        setActiveComponent('Quiz');
        setQuiz([]);
        setLoading(true);
        // setError(null);

        try {
            const resp = await getQuizQuestions(course_id, chapter_id);
            if (resp.data.length >= 5) {
                //flashcards to be shown, display them and exit
                let transformedData = resp.data.map((item) => ({
                    ...item,
                    question1: item.question, // Replace 'question' with 'question1'
                    correct_answer1: item.answer, // Replace 'answer' with 'correct_answer'
                    answer: undefined, // Remove old 'answer'
                    question: undefined, // Remove old 'question'
                }));
                generateNewQuiz(transformedData);
                setLoading(false);
                return;
            }

            openai.beta.threads.runs
                .list(threadID)
                .then((res1) => {
                    if (res1.body.first_id == null) {
                        openai.beta.threads.messages
                            .create(threadID, {
                                role: 'user',
                                content: `use the generateNewQuiz function five separate times with different questions than the last, and no similiar questions to help me study the content of ${chapter_name}.`,
                            })
                            .then((res) => {
                                runAssistant();
                                setLoading(true);
                            })
                            .catch((err) => console.log(err));
                    } else {
                        openai.beta.threads.runs
                            .retrieve(threadID, res1.body.first_id)
                            .then((res) => {
                                if (res.status == 'requires_action') {
                                    submitTools(res1.body.first_id);
                                    // setError(false)
                                } else if (
                                    res.status == 'completed' ||
                                    res.status == 'expired' ||
                                    res.status == 'failed' ||
                                    res.status == 'incomplete'
                                ) {
                                    // setError(false)
                                    openai.beta.threads.messages
                                        .create(threadID, {
                                            role: 'user',
                                            content: `use the generateNewQuiz function five separate times with different questions than the last, and no similiar questions to help me study the content of ${chapter_name}.`,
                                        })
                                        .then((res) => {
                                            runAssistant();
                                            setLoading(true);
                                        })
                                        .catch((err) => console.log(err));
                                } else {
                                    // setError(true)
                                    setTimeout(() => {
                                        createMessage();
                                    }, 5000);
                                }
                            })
                            .catch((err) => console.log('error'));
                    }
                })
                .catch((err) => console.log('error'));
        } catch (err) {
            // setError('Failed to generate quiz. Please try again.');
            setLoading(false);
            console.log('error', err);
        }
    }

    async function submitTools(run_id) {
        setLoading(true);

        const runStatus = await openai.beta.threads.runs.retrieve(threadID, run_id);

        const toolCalls = runStatus.required_action.submit_tool_outputs.tool_calls;

        const args = [];
        toolCalls.map((tool) => args.push(JSON.parse(tool.function.arguments)));
        const toolIds = [];
        const outputs = [];

        toolCalls.map((tool) => toolIds.push(tool.id));
        toolIds.map((toolID) =>
            outputs.push({
                tool_call_id: toolID,
                output: 'output',
            })
        );

        openai.beta.threads.runs
            .submitToolOutputs(threadID, run_id, {
                tool_outputs: outputs,
            })
            .then((res) => generateNewQuiz.apply(null, [args]));
    }

    async function runAssistant() {
        setLoading(true);
        openai.beta.threads.runs
            .create(threadID, {
                assistant_id: assistant.id,
            })
            .then((res) => {
                setLoading(true);
                retrieveRun(res.id);
            });
    }

    function retrieveRun(run_id) {
        openai.beta.threads.runs.retrieve(threadID, run_id).then((res) => {
            if (res.status == 'in_progress' || res.status == 'queued') {
                setLoading(true);
                setTimeout(() => {
                    retrieveRun(run_id);
                    console.log('running');
                }, 6000);
            } else if (res.status == 'requires_action') {
                submitTools(run_id);
                console.log('submitted');
            } else if (res.status == 'completed') {
                // setError(true)
                console.log('error');
                console.log(res);
                setLoading(false);
            }
        });
    }

    async function getThreads() {
        const resp = await getStudentThreads(user?.id);
        if (resp.success) {
            return resp;
        } else {
            throw new Error('Could not fetch threads');
        }
    }

    async function updateThreads(thread_id) {
        const resp = await putStudentThreads(user?.id, {
            thread_id: thread_id,
            to_update: 'quizzes_thread',
        });
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error('Could not update threads');
        }
    }

    function generateNewQuiz(array) {
        setLoading(true);
        setQuiz([]);
        setQuizScore([]);
        for (let i = 0; i < array.length; i++) {
            setQuizScore((prevScore) => [...prevScore, null]);
        }
        setQuizSubmitted(false);
        setQuiz(array);
        setLoading(false);
    }

    // async function getMessages() {
    //     const threadMessages = await openai.beta.threads.messages.list(threadID);
    //     console.log(threadMessages);
    // }

    useEffect(() => {
        getThreads()
            .then((res) => {
                if (res.data[0].quizzes_thread == ' ') {
                    openai.beta.threads
                        .create()
                        .then((res) => {
                            updateThreads(res.id);
                            setThreadID(res.id);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setThreadID(res.data[0].quizzes_thread);
                }
            })
            .catch((err) => console.log(err));
    }, [user]);

    // useEffect(() => {
    //     setQuiz([]);
    //     setQuizSubmitted(false);
    //     setQuizScore([]);
    // }, [chapter]);

    // useEffect(() => {
    //     if (threadID && chapter_name) {
    //         createMessage();
    //     }
    // }, [chapter_name]);
    // src/components/CourseLearning/Sidebar/QuizGenerate.jsx
    useEffect(() => {
        if (threadID && chapter_name) {
            // Reset states
            setQuiz([]);
            setQuizSubmitted(false);
            setQuizScore([]);

            // Generate new quiz
            createMessage();
        }
    }, [threadID, chapter_name]); // Dependency on chapter_name

    useEffect(() => {
        if (createNewQuiz) {
            createMessage();
            setCreateNewQuiz(false); // Reset the trigger after generating quiz
        }
    }, [createNewQuiz]);

    return (
        <button
            className="ace__AiGenerate-btn"
            onClick={createMessage}
            disabled={loading}
            style={{ opacity: loading ? 0.5 : 1 }}
        >
            {loading ? 'Loading...' : 'Generate Quiz'}
        </button>
    );
}

export default QuizGenerate;
