// Import necessary functions and middleware from Redux
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combineReducer from './reducer'; 


const initialState = {};

const middleware = [thunk];

const store = createStore(
    combineReducer, 
    initialState, 
    compose( 
        applyMiddleware(...middleware) 
    )
);

export default store;


// createStore: A function that creates a Redux store.
// applyMiddleware: A function that enhances the store with middleware.
// compose: A utility function that allows you to combine multiple store enhancers.
// thunk: A middleware that allows you to write action creators that return a function instead of an action. This is useful for handling asynchronous actions (like API calls).
// combineReducer: A function that combines multiple reducers into a single reducer function.



