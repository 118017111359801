import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import './ReportIssue.css';

import { useTranslation } from 'react-i18next';
// import { icons } from '../../constants/icons';
import { reportFlashcard, reportQuizQuestion, reportTutorResponse } from '../../services/ai';
import { showSuccess, showError } from '../../Utils/helper';

/**
 *
 * @param { questionInfo: {
 *              course_id: questionInfo.course_id,
 *              chapter_id: questionInfo.chapter_id,
 *              question: questionInfo.question,
 *              answer: questionInfo.answer,
 *              user_id: questionInfo.user_id,
 *              question_id: questionInfo.question_id,
 *          },
 *          closeModal,
 *          setShow,
 *          type: 'flashcard' | 'ai_quiz' | 'ai_tutor'}
 * @returns
 */

function ReportIssue({ onClose, questionInfo, type }) {
    const [reportText, setReportText] = useState('');

    // const handleSubmit = () => {
    //     console.log(reportText);
    //     setReportText('');
    //     onClose();
    // };
    const { t } = useTranslation();

    const [text, setText] = useState('');
    const maxCharacters = 500;

    const handleChange = (event) => {
        if (event.target.value.length <= maxCharacters) {
            setText(event.target.value);
        }
    };

    const remainingCharacters = maxCharacters - text.length;

    const handleSubmit = async () => {
        console.log(reportText);
        setReportText('');
        onClose();
        if (type === 'flashcard') {
            const resp = await reportFlashcard({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                question: questionInfo.question,
                answer: questionInfo.answer,
                user_id: questionInfo.user_id,
                question_id: questionInfo.question_id,
                reasons: text,
            })
                .then((res) => {
                    showSuccess(t('alertMessage.success'));
                    // closeModal();
                })
                .catch((err) => showError(t('alertMessage.wrong')));
        } else if (type === 'ai_quiz') {
            //type = ai_quiz
            const resp = await reportQuizQuestion({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                question: questionInfo.question,
                answer: questionInfo.answer,
                user_id: questionInfo.user_id,
                question_id: questionInfo.question_id,
                incorrect_answer1: questionInfo.incorrect_answer1,
                incorrect_answer2: questionInfo.incorrect_answer2,
                incorrect_answer3: questionInfo.incorrect_answer3,
                explanation: questionInfo.explanation,
                reasons: text,
            })
                .then((res) => {
                    showSuccess(t('alertMessage.success'));
                    // closeModal();
                })
                .catch((err) => showError(t('alertMessage.wrong')));
        } else if (type === 'ai_tutor') {
            const resp = await reportTutorResponse({
                course_id: questionInfo.course_id,
                chapter_id: questionInfo.chapter_id,
                user_id: questionInfo.user_id,
                response: questionInfo.response,
                reasons: text,
            })
                .then((res) => {
                    showSuccess(t('alertMessage.success'));
                    // closeModal();
                })
                .catch((err) => showError(t('alertMessage.wrong')));
        }
    };

    return (
        <div className="report-overlay" onClick={onClose}>
            <div className="report-modal" onClick={(e) => e.stopPropagation()}>
                <div className="report-modal__header">
                    <h2>Report an issue</h2>

                    <span className="icon-wrapper close-icon" onClick={onClose}>
                        <CloseIcon style={{ cursor: 'pointer' }} />
                    </span>
                </div>
                <div className="lrf_subtext">
                    <p>Please describe the issue you are facing</p>
                    <p>يرجى وصف المشكلة التي تواجهها</p>
                </div>
                {/* <p className="report-modal__description">Please describe an issue you are facing</p> */}
                <textarea
                    className="report-modal__textarea"
                    placeholder="Type your text here (Max 500 characters)"
                    maxLength={500}
                    // value={reportText}
                    value={text}
                    // onChange={(e) => setReportText(e.target.value)}
                    onChange={handleChange}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontSize: '14px',
                    }}
                >
                    {remainingCharacters}
                </div>
                <button className="report-modal__submit" onClick={handleSubmit}>
                    {t('common.submit')}
                </button>
            </div>
        </div>
    );
}

export default ReportIssue;
