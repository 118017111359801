import './StudyGuide.css';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../../services/helper';
// import { Description } from '@mui/icons-material';

function StudyGuide({ courseData }) {
    const { t, i18n } = useTranslation();
    // console.log('-------StudyGuide Debugging:-------');
    // console.log('StudyGuide - courseData:', courseData);

    return (
        <div className="studyGuide" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
            <div className="studyGuide__about">
                <h1 className="studyGuide__about--title">{courseData?.name}</h1>
                {/* <p className="studyGuide__about--content">{courseData?.description}</p> */}
                {/* {courseData?.description} */}
                <div
                    className="studyGuide__about--content"
                    dangerouslySetInnerHTML={{
                        __html: t('dynamic', {
                            en: courseData?.description?.replace(/['"]+/g, ''),
                            ar: getTranslationFromMany(
                                courseData?.translation,
                                'description',
                                courseData?.description?.replace(/['"]+/g, '')
                            ),
                        }),
                    }}
                />
            </div>

            <div className="studyGuide__object">
                <h1 className="studyGuide__object--title" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    {t('common.learningObjectives')}
                </h1>
                <ul className="studyGuide__object--list">
                    {courseData?.course_objectives?.map((obj) => (
                        <li className="studyGuide__object--item" key={obj?.id}>
                            <AssignmentTurnedInIcon
                                style={{
                                    fontSize: '20px',
                                    color: 'green',
                                }}
                            />
                            <p>
                                {t('dynamic', {
                                    en: obj?.objective,
                                    ar: obj?.translation?.value ? obj?.translation?.value : obj?.objective,
                                })}
                            </p>
                        </li>
                    ))}

                    {/* Add more learning objects as needed */}
                </ul>
            </div>
        </div>
    );
}

export default StudyGuide;
