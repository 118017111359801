// import {
//     arrowRight,
//     checkBox,
//     checkIcon,
//     playXS,
//   } from "../../assets/icons/icons";
import './CourseContent.css';
import React, { useState, useRef } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// Import accordion components for UI
import { useTranslation } from 'react-i18next';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function CourseContent({
    setActiveComponent,
    setSidebarTop,
    courseData,
    // Add the new props
    currentLesson,
    check,
    markAsCompleted,
    unmarkComplete,
    setCurrentLesson,
    setView,
    setVideoUrl,
    setVideoEnd,
    setIsDownloadable,
    setUniqueId,
    setLoading,
}) {
    const [openChapters, setOpenChapters] = useState({});
    const { t, i18n } = useTranslation();
    const [activeChapter, setActiveChapter] = useState(null);
    const [activeLessonID, setActiveLessonID] = useState(null);
    const contentRef = useRef(null);
    console.log('--------coursecontent debugging--------');

    const handleTaskClick = (_lesson) => {
        // setLoading(true);
        // console.log('coursecontent- lesson', _lesson);
        // console.log('coursecontent- lesson media', _lesson?.media[0]);

        setActiveLessonID(_lesson?.id);
        setActiveComponent('Course');
        handleLessonClick(_lesson);

        // Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smooth scrolling
        });

        // Scroll course content list to top
    };

    const handleLessonClick = (lesson) => {
        // if (!lesson) {
        //     throw new Error('Lesson data is unavailable');
        // }

        if (lesson?.type === 'quiz' || lesson?.type === 'live-session') {
            // setLoading(false);
            setCurrentLesson(lesson);
            setView(lesson?.type);
        } else {
            // setLoading(false);
            // if (!lesson?.media?.[0]?.url) {
            //     throw new Error('Media content is currently unavailable');
            // }
            setVideoUrl(lesson?.media[0]?.url);
            setCurrentLesson(lesson);
            setVideoEnd(false);
            setView(lesson?.media[0]?.media_type);
            setIsDownloadable(lesson?.media[0]?.is_downloadable);
        }
        // setLoading(false);
    };

    const handleChapterToggle = (chapterId) => {
        // if (contentRef.current) {
        //     contentRef.current.scrollTo({
        //         top: 0,
        //         behavior: 'smooth',
        //     });
        // }

        setOpenChapters((prev) => ({
            ...prev,
            [chapterId]: !prev[chapterId],
        }));
    };

    const handleTaskComplete = (_lesson) => {
        if (document.getElementById(_lesson?.id).checked !== false) {
            markAsCompleted(_lesson);
        } else {
            unmarkComplete(_lesson);
        }
    };

    console.log('--------coursecontent debugging--------');
    // console.log('coursecontent- courseData', courseData);

    return (
        <div className="courseContent">
            {courseData?.chapter?.map((_chapter, index) => (
                <div className="courseContente__box" key={_chapter?.id} uuid={_chapter?.id}>
                    <div
                        className={`courseContent__item ${activeChapter === index ? 'active' : ''}`}
                        onClick={() => {
                            setActiveChapter(index);
                            handleChapterToggle(_chapter?.id);
                        }}
                    >
                        <div className="courseContent__item--left">
                            <NavigateNextOutlinedIcon
                                className={openChapters[_chapter?.id] ? 'arrow-down' : ''}
                                style={{ fontSize: '24px', marginRight: '6px' }}
                            />
                            <h1>
                                {t('dynamic', {
                                    en: _chapter?.name,
                                    ar: _chapter?.translation?.value ? _chapter?.translation?.value : _chapter?.name,
                                })}
                            </h1>
                        </div>
                    </div>

                    {openChapters[_chapter?.id] && (
                        <div className="courseContent__task">
                            <div className="courseContent__task--content">
                                <nav>
                                    <ul>
                                        {_chapter?.lessons?.map((_lesson, lessonIndex) => (
                                            <li
                                                key={_lesson?.id}
                                                onClick={() => handleTaskClick(_lesson)}
                                                className={activeLessonID === _lesson?.id ? 'active' : ''}
                                            >
                                                <div className="courseContent__task--topic">
                                                    <div className="courseContent__task--check ">
                                                        <input
                                                            className="courseContent__task-check-icon"
                                                            type="checkbox"
                                                            id={_lesson?.id}
                                                            checked={
                                                                _lesson?.student_update_lesson !== null ||
                                                                check.includes(_lesson?.id)
                                                                    ? true
                                                                    : false
                                                            }
                                                            onClick={() => handleTaskComplete(_lesson)}
                                                        />
                                                    </div>
                                                    <div className="courseContent__task-title">
                                                        <span className="task-number">{lessonIndex + 1}.</span>
                                                        &nbsp;
                                                        {t('dynamic', {
                                                            en: _lesson?.name,
                                                            ar: _lesson?.translation?.value
                                                                ? _lesson?.translation?.value
                                                                : _lesson?.name,
                                                        })}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default CourseContent;
