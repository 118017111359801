import React, { useEffect, useState, useRef, useContext } from 'react';
import { withRouter, useLocation } from 'react-router';

import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Navbar from '../../navigation/Navbar';
import QAForm from '../../modals/QAForm';

import { UserContext } from '../../context/AuthContext';
import { getAllComments, postComments } from '../../services/discussionForum';
import { showError, utcToLocalDateTime, Spinner, showSuccess } from '../../Utils/helper';

import { icons } from '../../../constants/icons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

import './AskInstructor.css';

function AskInstructor({ instructor, onBack }) {
    const { t } = useTranslation();
    const messageBox = useRef(null);
    const { user } = useContext(UserContext);
    const location = useLocation();

    // const param_user = location.state.data;

    const [data, setData] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    // const _id = props.match.params.id;
    const { id: instructor_id, full_name: instructor_name } = instructor;

    console.log('--------chat debug--------');
    // console.log('chat props-', props);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [show]);

    async function getComments() {
        setLoading(true);
        const resp = await getAllComments(instructor_id);
        if (resp.success) {
            setData(resp.data.reverse());
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    useEffect(() => {
        getComments();
    }, []);

    async function sendMessage(e) {
        e.preventDefault();
        setMessage('');
        setLoading(true);
        if (message.length > 0) {
            let payload = {
                comment: message,
            };
            const resp = await postComments(instructor_id, payload);
            if (resp.success) {
                // console.log("message sent");
            } else {
                showError(t('alertMessage.wrong'));
            }
            getComments();
        }
        setLoading(false);
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [data]);

    const [openQAModal, closeQAModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeQAModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <QAForm modalClose={closeQAModal} showSuccess={showSuccess} />
            </ReactModal>
        );
    });

    function handleFileChange(e) {
        const { name, files, value } = e.target;
        if (e.target.files[0].size / 1024 / 1024 < 30) {
            setLoading(true);
            let fileExt = value.split('.');
            let fileExtReverse = fileExt.reverse();
            getBase64(files[0]).then((data) => {
                sendAttachment(data, fileExtReverse[0]);
            });
            setLoading(false);
        } else {
            e.target.value = null;
            showError(t('alertMessage.fileNotMoreThan'));
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    async function sendAttachment(data, fileExt) {
        if (data !== '' && data !== null && fileExt !== '' && fileExt !== null) {
            let payload = {
                msg_type: 'attachment',
                attachment: data,
                file_ext: fileExt,
            };
            const resp = await postComments(instructor_id, payload);
            if (resp.success) {
                console.log('message sent');
            } else {
                if (resp.status === 422) {
                    showError(t('alertMessage.fileNotMoreThan'));
                } else {
                    showError(t('alertMessage.wrong'));
                }
            }
            getComments();
        }
    }
    return (
        <section className="ask-instructor">
            <div class="ask-instructor__header">
                <button className="ask-instructor__back-button" onClick={onBack}>
                    <ArrowBackIcon />
                </button>
                <div class="ask-instructor__profile">
                    <div className="ask-instructor__profile-image">{instructor_name[0]}</div>
                    <h1>{instructor_name}</h1>
                </div>

                {user?.type === 'instructor' && (
                    <button className="btn btn-primary mt-3 QAButton" onClick={openQAModal}>
                        Highlight Question
                    </button>
                )}
            </div>

            <div class="msg_win">
                <>
                    {data?.length === 0 ? (
                        <div className=" d-flex justify-content-center align-items-center empty-chat">
                            <h2 className="text-center">{t('common.startConversation')}</h2>
                        </div>
                    ) : (
                        <>
                            {data &&
                                data?.map((_comment) => {
                                    return (
                                        <div key={_comment?.id}>
                                            {_comment?.user_id == instructor_id ? (
                                                <div class="ask-instructor__reply-text">
                                                    {_comment.attachment !== '' && _comment.attachment !== null ? (
                                                        <div class="msg_file">
                                                            <a
                                                                href={
                                                                    process.env.REACT_APP_ASSETS +
                                                                    _comment?.attachment?.attachment_path
                                                                }
                                                                className="download_button"
                                                                download
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={icons.icon_download_black} alt="download" />
                                                            </a>
                                                            {_comment?.attachment?.attachment_file}
                                                        </div>
                                                    ) : (
                                                        <p>{_comment?.comment}</p>
                                                    )}

                                                    <div class="reply_date">
                                                        {utcToLocalDateTime(_comment?.created_at)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div class=" ask-instructor__send-text">
                                                    {_comment.attachment !== '' && _comment.attachment !== null ? (
                                                        <div class="msg_file">
                                                            <a
                                                                href={
                                                                    process.env.REACT_APP_ASSETS +
                                                                    _comment?.attachment?.attachment_path
                                                                }
                                                                className="download_button"
                                                                download
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={icons.icon_download} alt="download" />
                                                            </a>
                                                            {_comment?.attachment?.attachment_file}
                                                        </div>
                                                    ) : (
                                                        <p>{_comment?.comment}</p>
                                                    )}
                                                    <div class="send_date">
                                                        {utcToLocalDateTime(_comment?.created_at)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            {/* <AlwaysScrollToBottom /> */}
                            <div ref={messagesEndRef} />
                        </>
                    )}
                </>
            </div>
            <div class="ask-instructor__menu">
                <div class="msg_input">
                    <form onSubmit={sendMessage} className="grey_input w-100 mt-0">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t('common.typeMessage')}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className="attachment_button">
                            <input type="file" id="attachment" onChange={handleFileChange} />
                            <label className="form-label" for="attachment">
                                <img src={icons.icon_attachment} alt="" />
                            </label>
                        </div>
                        <span className="ml-2 svg_icon cursor_pointer" onClick={sendMessage}>
                            <SendIcon />
                        </span>
                    </form>
                </div>
            </div>

            <ToastContainer />
        </section>
    );
}

export default AskInstructor;
