// Import the combineReducers function from Redux
import { combineReducers } from 'redux';
// Import individual reducers from their respective files
import registerStudent from '../containers/user/reducers/registerStudent';
import me from '../containers/user/reducers/me';
import loginData from '../containers/user/reducers/login';
import forgotPasswordMail from '../containers/user/reducers/forgotPasswordMail';
import forgotVerifyOtp from '../containers/user/reducers/forgotVerifyOtp';
import newPassword from '../containers/user/reducers/newPassword';
import registerInstructor from '../containers/user/reducers/registerInstructor';
import emailVerification from '../containers/user/reducers/signupEmailVerification';
import country from '../containers/Utils/reducers/country';
import profile from '../containers/profile/reducers/profile';
import resendOtp from '../containers/user/reducers/resendOtp';
import unauthedCountryReducer from '../containers/unauthedUser/reducers/unauthedCountry';

export default combineReducers({
    registerStudent: registerStudent,
    loginData: loginData,
    me: me,
    forgotPasswordMail: forgotPasswordMail,
    forgotVerifyOtp: forgotVerifyOtp,
    newPassword: newPassword,
    registerInstructor: registerInstructor,
    emailVerification: emailVerification,
    country: country,
    profile: profile,
    resendOtp: resendOtp,
    unauthedUserInfo: unauthedCountryReducer,
});
