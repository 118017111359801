import { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../context/AuthContext';

import Flashcards from '../studentMyLearning/Flashcards';
import Quizzes from '../studentMyLearning/Quizzes';

import '../../assets/styles/aifeatures.css';
import ourLogo from '../../assets/image/dawraty-logo.png';

import { getDataCall, getAuthDataCall } from '../services/utils';
import { useTranslation } from 'react-i18next';

function AiFeatures(props) {
    const { course_id, course } = props;

    const { t } = useTranslation();

    const [selectedFeature, setSelectedFeature] = useState('');
    const [selectedChapter, setSelectedChapter] = useState({
        name: null,
        id: null,
    });
    const [availableChapters, setAvailableChapters] = useState([]);
    const [purchasedChapters, setPurchasedChapters] = useState([]);
    const [chaptersLoaded, setChaptersLoaded] = useState(false);

    useEffect(() => {
        getAvailableChapters();
    }, []);

    useEffect(() => {
        if (availableChapters.length > 0) {
            getStudentChapters();
        }
    }, [availableChapters]);

    function fetchFeature() {
        switch (selectedFeature) {
            case 'flashcards':
                return (
                    <Flashcards chapter={selectedChapter.name} course_id={course_id} chapter_id={selectedChapter.id} />
                );
                break;
            case 'quizzes':
                return <Quizzes chapter={selectedChapter.name} course_id={course_id} chapter_id={selectedChapter.id} />;
                break;
        }
    }

    async function getAvailableChapters() {
        let resp;
        resp = await getAuthDataCall(`/auth/ai/available_courses_chapters`);

        let chaptersSetter = [];
        if (resp.success) {
            for (let i = 0; i < resp.data.length; i++) {
                if (resp.data[i].course_id == 0) {
                    chaptersSetter.push(resp.data[i].chapter_id);
                }
            }
            setAvailableChapters(chaptersSetter);
        } else {
            console.log('error');
            console.log(resp);
        }
    }

    async function getStudentChapters() {
        const resp = await getAuthDataCall(`/auth/studentCourse`);
        if (resp.success) {
            let setter = [];

            if (resp.data.length > 0) {
                for (let i = 0; i < resp.data.length; i++) {
                    if (resp.data[i].course_id == course_id) {
                        for (let x = 0; x < resp.data[i].courses.chapter.length; x++) {
                            setter.push({
                                name: resp.data[i].courses.chapter[x].name,
                                id: resp.data[i].courses.chapter[x].id,
                            });
                        }
                    }
                }

                setPurchasedChapters(setter);
                setChaptersLoaded(true);
            }
        }
    }

    function radioButtonStyles(feature) {
        if (selectedChapter.name == null) {
            return { backgroundColor: 'grey', border: '1px solid grey' };
        } else {
            if (selectedFeature == feature) {
                return { backgroundColor: '#0b4475' };
            }
        }
    }

    return (
        <div id="ai-features-container">
            {chaptersLoaded ? (
                <div id="header-options">
                    <select
                        onChange={(e) =>
                            setSelectedChapter({
                                name: e.target.options[e.target.selectedIndex].getAttribute('name'),
                                id: e.target.value,
                            })
                        }
                        name="chapter-select"
                        id="chapter-select"
                    >
                        <option value="" disabled selected>
                            Select Chapter
                        </option>
                        {purchasedChapters.map((chapter, key) => (
                            <option key={key} name={chapter.name} value={chapter.id}>
                                {chapter.name}
                            </option>
                        ))}
                    </select>

                    <div id="feature-select">
                        <div
                            onClick={() => {
                                if (selectedChapter.name != null) {
                                    setSelectedFeature('flashcards');
                                }
                            }}
                            className="feature-select-option"
                            style={selectedChapter.name == null ? { background: 'grey' } : {}}
                        >
                            {/* <div className="radio-button" style={radioButtonStyles('flashcards')} /> */}
                            <span>Flashcards</span>
                        </div>

                        <div
                            onClick={() => {
                                if (selectedChapter.name != null) {
                                    setSelectedFeature('quizzes');
                                }
                            }}
                            className="feature-select-option"
                            style={selectedChapter.name == null ? { background: 'grey' } : {}}
                        >
                            {/* <div className="radio-button" style={radioButtonStyles('quizzes')} /> */}
                            <span>Quizzes</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div class="loading-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            {selectedFeature == '' && chaptersLoaded
                ? // <div className="default-screen" style={{ marginTop: '20px' }}>
                  //     <img src={ourLogo} style={{ width: '150px' }} />
                  //     <span>{t('aifeatures.introText1')}</span>
                  //     <span>{t('aifeatures.introText2')}</span>
                  // </div>
                  null
                : fetchFeature()}
        </div>
    );
}

export default AiFeatures;
