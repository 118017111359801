import React, { useEffect } from 'react';
import Navbar from '../navigation/Navbar';
import Footer from '../navigation/Footer';

function PageLayout(props) {
    return (
        <>
        {/* Render the Navbar component, passing the 'me' prop to it */}
        {/* The 'me' prop typically contains user information or context needed by the Navbar */}
            <Navbar me={props.me} />       
         {/* Render the children components passed to this PageLayout component */}
         {/* This allows for dynamic content to be displayed within the layout */}
            {props.children}
            <Footer />
          {/* Render the Footer component, which typically contains copyright information, links, etc. */}
        </>
    );
}

export default PageLayout;

// We have to use this component on all the pages
