import { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

import {
    upvoteQuizQuestion,
    unupvoteQuizQuestion,
    downvoteQuizQuestion,
    undownvoteQuizQuestion,
    favoriteQuizQuestion,
    getQuizQuestions,
} from '../../services/ai';

import ReportIssue from './ReportIssue';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';

import './Quiz.css';

function Quiz({ handleActiveSidebar, chapter_id, course_id, quiz, setCreateNewQuiz }) {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [selectedAnswers, setSelectedAnswers] = useState(Array(quiz.length).fill(null));
    const [submitted, setSubmitted] = useState(false);

    // State to track interactions for each quiz question
    const [quizStates, setQuizStates] = useState({});
    const [reportIndex, setReportIndex] = useState(null);

    const handleThumbsUp = async (e, question, index) => {
        e.stopPropagation(); // Prevent the card flip event
        // Safely access the current state with default values
        const currentState = quizStates[index] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
            // rating: null,
        };
        const newThumbsUpState = !currentState.thumbsUpClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                thumbsUpClicked: newThumbsUpState,
                thumbsDownClicked: false,
            },
        }));

        try {
            if (newThumbsUpState) {
                // remove upvote
                await unupvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            } else {
                await upvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    is_downvoted: quizStates[index].thumbsDownClicked === true ? true : false,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling thumbs up:', error);
        }
    };

    const handleThumbsDown = async (e, question, index) => {
        e.stopPropagation(); // Prevent the card flip event

        const currentState = quizStates[index] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newThumbsDownState = !currentState.thumbsDownClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                thumbsDownClicked: newThumbsDownState,
                thumbsUpClicked: false,
            },
        }));

        try {
            if (newThumbsDownState) {
                // remove upvote
                await downvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    is_upvoted: quizStates[index].thumbsUpClicked === true ? true : false,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            } else {
                await undownvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,

                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling thumbs down:', error);
        }
    };

    const handleFavorite = async (e, question, index) => {
        e.stopPropagation();

        // Safely access the current state with default values
        const currentState = quizStates[index] || {
            // thumbsUpClicked: false,
            // thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newFavoriteState = !currentState.favoriteClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                favoriteClicked: newFavoriteState,
            },
        }));
        try {
            if (newFavoriteState) {
                await favoriteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    user_id: user?.id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling favorite:', error);
        }
    };

    const handleAnswerSelect = (questionIndex, answer) => {
        if (submitted) return; // Prevent changing answers after submission
        setSelectedAnswers((prevSelectedAnswers) => {
            const newSelectedAnswers = [...prevSelectedAnswers];
            newSelectedAnswers[questionIndex] = answer;
            return newSelectedAnswers;
        });
    };

    const handleSubmit = () => {
        setSubmitted(true);
    };

    const calculateScore = () => {
        return selectedAnswers.reduce((score, answer, index) => {
            if (answer === quiz[index].correct_answer1) {
                // Correct answer is the first element
                return score + 1;
            }
            console.log(score);
            return score;
        }, 0);
    };

    const handleReportIssue = async (e, index) => {
        e.stopPropagation(); // Prevent the card flip event
        setReportIndex(index);
    };

    return (
        <>
            <div className="quiz">
                {quiz.map((questionData, index) => {
                    const {
                        correct_answer1,
                        explanation,
                        incorrect_answer1,
                        incorrect_answer2,
                        incorrect_answer3,
                        question1,
                    } = questionData;

                    const answers = [correct_answer1, incorrect_answer1, incorrect_answer2, incorrect_answer3];
                    const currentState = quizStates[index] || {
                        thumbsUpClicked: false,
                        thumbsDownClicked: false,
                        favoriteClicked: false,
                    };

                    return (
                        <div key={index} className="quiz-main">
                            <div className="quiz__content">
                                <div className="quiz__content-title">
                                    <span className="quiz-question-text">
                                        {index + 1}.{questionData.question1}
                                    </span>
                                </div>
                                <div className="quiz-answers">
                                    {answers.map((answer, i) => {
                                        let answerClass = '';
                                        if (submitted) {
                                            if (answer === correct_answer1) {
                                                answerClass = 'correct';
                                            } else if (selectedAnswers[index] === answer) {
                                                answerClass = 'incorrect';
                                            }
                                        } else if (selectedAnswers[index] === answer) {
                                            answerClass = 'selected';
                                        }

                                        return (
                                            <div
                                                key={i}
                                                className={`quiz-answer ${answerClass}`}
                                                onClick={() => handleAnswerSelect(index, answer)}
                                            >
                                                {String.fromCharCode(65 + i)}. {answer}
                                            </div>
                                        );
                                    })}
                                </div>
                                {submitted && (
                                    <div>
                                        <p
                                            style={{
                                                color: selectedAnswers[index] === correct_answer1 ? 'limegreen' : 'red',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Explanation: {explanation}
                                        </p>
                                        <div className="quiz__content-rates">
                                            <div
                                                className={`quiz__content-menu-icon ${
                                                    currentState.favoriteClicked ? 'active' : ''
                                                }`}
                                                onClick={(e) => handleFavorite(e, questionData, index)}
                                            >
                                                {currentState.favoriteClicked ? (
                                                    <FavoriteIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                                                ) : (
                                                    <FavoriteBorderOutlinedIcon
                                                        style={{ fontSize: '24px', color: '#0b4374' }}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className={`quiz__content-rates-icon ${
                                                    currentState.thumbsUpClicked ? 'active' : ''
                                                }`}
                                                onClick={(e) => handleThumbsUp(e, questionData, index)}
                                            >
                                                <ThumbUpAltOutlinedIcon
                                                    style={{
                                                        fontSize: '20px',
                                                        color: currentState.thumbsUpClicked ? '#ffffff' : '#0b4374',
                                                        // border: '1px solid red',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`quiz__content-rates-icon ${
                                                    currentState.thumbsDownClicked ? 'active' : ''
                                                }`}
                                                onClick={(e) => handleThumbsDown(e, questionData, index)}
                                            >
                                                <ThumbDownAltOutlinedIcon
                                                    style={{
                                                        fontSize: '20px',
                                                        color: currentState.thumbsDownClicked ? '#ffffff' : '#0b4374',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="quiz__content-rates-icon"
                                                onClick={(e) => handleReportIssue(e, index)}
                                            >
                                                <GppMaybeOutlinedIcon style={{ fontSize: '20px', color: '#0b4374' }} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {reportIndex === index && (
                                    <ReportIssue
                                        onClose={() => setReportIndex(null)}
                                        questionInfo={{
                                            course_id,
                                            chapter_id,
                                            user_id: user?.id,
                                            question_id: questionData.id,
                                            question: questionData.question1,
                                            answer: questionData.correct_answer1,
                                        }}
                                        type="quizzes"
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
                {submitted && (
                    <>
                        <h4 className="quiz__score">
                            Score: {calculateScore()} / {quiz.length}
                        </h4>
                        <button
                            onClick={() => setCreateNewQuiz(true)}
                            className="secondary-button-fill quiz__generate-more-button"
                        >
                            Generate More Quizzes
                        </button>
                    </>
                )}
                {!submitted && (
                    <button onClick={handleSubmit} className="primary-button-fill quiz__check-button">
                        Check
                    </button>
                )}

                <div className="block-20">
                    <p></p>
                </div>
            </div>
        </>
    );
}
export default Quiz;
