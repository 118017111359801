// src/components/Common/ErrorBoundary.jsx
import React from 'react';
import './ErrorBoundary.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-container">
                    <ErrorOutlineIcon className="error-icon" style={{ fontSize: '48px', color: 'var(--error)' }} />
                    <h2>Something went wrong</h2>
                    {/* <p>{this.state.error.message}</p> */}
                    <button onClick={() => window.location.reload()}>Try again</button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
