// src/components/CourseLearning/Sidebar/Connect.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AskInstructor from './AskInstructor';
import './Connect.css';

function Connect({ data }) {
    const { t } = useTranslation();
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const [showAskInstructor, setShowAskInstructor] = useState(false);

    console.log('-------connect-------');
    // console.log('connect data', data);
    // console.log('connect user', user);
    // console.log('instructorToAsk', instructorToAsk);
    // console.log('instructors', data?.instructors);

    const handleAskQuestion = (instructor) => {
        setSelectedInstructor({
            id: instructor.id,
            full_name: instructor.full_name,
        });
        setShowAskInstructor(true);
    };

    const handleBack = () => {
        setShowAskInstructor(false);
        setSelectedInstructor(null);
    };

    if (showAskInstructor && selectedInstructor) {
        return <AskInstructor instructor={selectedInstructor} onBack={handleBack} />;
    }

    return (
        <div className="connect">
            <h2 className="connect__title">
                {/* {t('common.instructors')} */}
                {t('common.askYourQuestion')}
            </h2>
            <div className="connect__instructors">
                {data?.instructors?.map((instructor) => (
                    <div key={instructor.id} className="connect__instructor-card">
                        <div className="connect__instructor-profile">
                            {instructor.img_path ? (
                                <img
                                    src={`${process.env.REACT_APP_ASSETS}${instructor.img_path}`}
                                    alt={instructor.full_name}
                                    className="connect__instructor-image"
                                />
                            ) : (
                                <div className="connect__instructor-initials">{instructor.last_name.charAt(0)}</div>
                            )}
                        </div>
                        <h3 className="connect__instructor-name">{instructor.full_name}</h3>

                        <button className="connect__ask-button" onClick={() => handleAskQuestion(instructor)}>
                            {t('common.askQuestion')}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Connect;
