import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Loader from './components/Common/Loader';
import './services/i18n';
import './index.css'

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<Loader />}>
            <App />
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
