// src/components/common/TextLoadingAnimation.jsx
import React from 'react';
// import './TextLoadingAnimation.css';
import './Loading.css';

const TextLoading = ({
    lines = 4,
    width = 'random', // 'random', 'full', or specific percentage/px
    height = '20px',
    className = '',
}) => {
    const getWidth = (index) => {
        if (width === 'full') return '100%';
        if (width === 'random') {
            // Generate random widths for different lines
            const widths = ['60%', '75%', '90%'];
            return widths[index % widths.length];
        }
        return width;
    };

    return (
        <div className={`text-loading-container ${className}`}>
            {/* <div className="flashcard__header"> */}
            <h1 className="text-loading-container__title">Dawraty</h1>
            {/* </div> */}
            {[...Array(lines)].map((_, index) => (
                <div
                    key={index}
                    className="loading-line"
                    style={{
                        width: getWidth(index),
                        height: height,
                    }}
                />
            ))}
        </div>
    );
};

const CircleLoading = ({ size = 'medium', color = 'primary', className = '' }) => (
    <div className={`circle-loading ${size} ${color} ${className}`}>
        <div className="circle-loading__spinner" />
    </div>
);

// Flashcard Loading Skeleton
const FlashcardLoading = () => {
    return (
        <div className="flashcard-loading">
            <div className="flashcard-loading__header">
                <TextLoading lines={1} width="120px" height="24px" />
            </div>
            <div className="flashcard-loading__content">
                <TextLoading lines={3} className="flashcard-text" />
            </div>
            <div className="flashcard-loading__footer">
                <TextLoading lines={1} width="60px" height="16px" />
            </div>
        </div>
    );
};

// Quiz Loading Skeleton
const QuizLoading = () => {
    return (
        <div className="quiz-loading">
            <div className="quiz-loading__question">
                <TextLoading lines={2} className="question-text" />
            </div>
            <div className="quiz-loading__options">
                {[...Array(4)].map((_, index) => (
                    <div key={index} className="option-loading">
                        <TextLoading lines={1} width="80%" height="20px" />
                    </div>
                ))}
            </div>
        </div>
    );
};

// Main Loading Component
const Loading = ({ type = 'text', ...props }) => {
    const loadingComponents = {
        text: TextLoading,
        circle: CircleLoading,
        flashcard: FlashcardLoading,
        quiz: QuizLoading,
    };

    const LoadingComponent = loadingComponents[type] || TextLoading;
    return <LoadingComponent {...props} />;
};

// Named exports for direct use
export { TextLoading, CircleLoading, FlashcardLoading, QuizLoading };

// Default export for general use
export default Loading;
